import { Box, Button, Container, Grid, Hidden, makeStyles, Typography } from '@material-ui/core'
import { EventAvailableOutlined, Explore, MonetizationOnOutlined, ReceiptOutlined } from "@material-ui/icons"
import BudgetExample from 'assets/examples/budget-example.gif'
import NavigationExample from 'assets/examples/navigation-example.gif'
import PlannerExample from 'assets/examples/planner-example.gif'
import TrackingExample from 'assets/examples/tracking-example.gif'
import iOSAppStore from 'assets/images/ios-download-on-the-app-store.svg'
import Screenshot2XImg from 'assets/images/screenshot-dekstop-mixed-2x.png'
import ScreenshotiOSImg from 'assets/images/screenshot-ios.png'
import { GetStarted } from 'components/AuthButtons'
import BaseLayout from 'components/BaseLayout'
import Footer from 'components/Footer'
import Header from 'components/Header'
import React from 'react'

const useStyles = makeStyles({
  cardIcon: {
    marginRight: "0.75rem",
    width: "2em",
    height: "2em"
  },
  example: {
    borderRadius: "6px",
  },
  plan: {
    width: "100%",
    backgroundColor: "#000"
  },
  budget: {
    width: "100%"
  },
  track: {
    width: "100%",
    backgroundColor: "#000"
  },
  features: {
    width: "100%"
  }
})

export default () => {
  const classes = useStyles()

  return (
    <BaseLayout indexable={true}>
      <Header />


      <Container maxWidth="lg">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box>
            <Typography variant="h2">
              Plan, Budget, and Track
            </Typography>
          </Box>
          <Box>
            <Typography variant="h3" gutterBottom>
            On Your Schedule
            </Typography>
          </Box>
        </Box>
      </Container>

      <Container maxWidth="md">
        <Box p={4}>
          <Grid container justify="center" spacing={4}>
            <Grid item xs={11} md={7}>
              <Typography variant="body1" gutterBottom>
                Budgetocity™ is the app that you have been looking for to take control of your finances!
                Budgetocity breaks the mold of the traditional monthly budget by allowing you to build your 
                budget around your income schedule!  Budgetocity also has an integrated planner that
                allows you to keep track of events and automatically insert them into your budget!
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box display="flex" flexDirection={{xs: "row", md: "column"}} justifyContent="center" alignItems="center">
                <Box py={1}>
                  <GetStarted />
                </Box>
                <Box py={1}>
                  <Button href="https://apps.apple.com/us/app/budgetocity/id1546790703">
                    <img src={iOSAppStore} alt="Download on the Apple App Store" />
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container maxWidth="md">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mb={5}>
            <Hidden xsDown>
              <img src={Screenshot2XImg} alt="Screenshot" width="100%"/>
            </Hidden>
            <Hidden smUp>
              <Box display="flex" justifyContent="center">
                <img src={ScreenshotiOSImg} alt="Screenshot" width="240px"/>
              </Box>
            </Hidden>
          </Box>
        </Box>
      </Container>


      {/**
        * PLAN
        */}

      <Box className={classes.plan}>
        <Container maxWidth="lg">
          <Box py={8}>
            <Grid container justify="center" spacing={3}>
              <Grid item xs={11} md={5}>
                <Typography variant="h4" component="h3" gutterBottom>
                  <Box display="flex" alignItems="center" flexWrap="wrap" wordSpacing={2} mt={{xs: 0, md: 12}}>
                    <EventAvailableOutlined className={classes.cardIcon} /> Plan
                  </Box> 
                </Typography>
                <Typography variant="h6" color="textSecondary" component="p" gutterBottom>
                  No More Surprises!
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p" gutterBottom>
                  Starting with your income, build your schedule so you can plan your budget around when you get paid.  Prepare for monthly, yearly, or even oddly scheduled bills.
                </Typography>
                {/*<Button size="small">Learn More</Button>*/}
              </Grid>
              <Grid item xs={12} md={1}></Grid>
              <Grid item xs={11} md={5}>
                <img src={PlannerExample} alt="Planner Example" width="100%" className={classes.example}/>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>


      {/**
        * BUDGET
        */}



      <Box className={classes.budget}>
        <Container maxWidth="lg">
          <Box py={8}>
            <Grid container justify="center" spacing={3}>
              <Hidden smDown>
                <Grid item xs={12} md={5}>
                  <img src={BudgetExample} alt="Budget Example" width="100%" className={classes.example}/>
                </Grid>
              </Hidden>
              <Grid item xs={12} md={1}></Grid>
              <Grid item xs={11} md={5}>
                <Typography variant="h4" component="h3" gutterBottom>
                  <Box display="flex" alignItems="center" flexWrap="wrap" wordSpacing={2} mt={{xs: 0, md: 6}}>
                    <MonetizationOnOutlined className={classes.cardIcon} /> Budget
                  </Box> 
                </Typography>
                <Typography variant="h6" color="textSecondary" component="p" gutterBottom>
                  No Dollar Left Behind!
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p" gutterBottom>
                  Make every dollar count with a traditional "zero based budget".  But here's the twist - the plan helps drive your budget.  Know when you need to budget for something, and when you don't, automatically!
                </Typography>
                {/*<Button size="small">Learn More</Button>*/}
              </Grid>
              <Hidden mdUp>
                <Grid item xs={11} md={7}>
                  <img src={BudgetExample} alt="Budget Example" width="100%" className={classes.example}/>
                </Grid>
              </Hidden>
            </Grid>
          </Box>
        </Container>
      </Box>


      {/**
        * TRACK
        */}

      <Box className={classes.track}>
        <Container maxWidth="lg">
          <Box py={8}>
            <Grid container justify="center" spacing={3}>
              <Grid item xs={11} md={5}>
                <Typography variant="h4" component="h3" gutterBottom>
                  <Box display="flex" alignItems="center" flexWrap="wrap" wordSpacing={2} mt={{xs: 0, md: 12}}>
                    <ReceiptOutlined className={classes.cardIcon} /> Track
                  </Box> 
                </Typography>
                <Typography variant="h6" color="textSecondary" component="p" gutterBottom>
                  Know What You Have!
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p" gutterBottom>
                  Track your spending so you stay within your budget.  With premium features, you can sync your tracking with your bank so you always know how much money you have left.
                </Typography>
                {/*<Button size="small">Learn More</Button>*/}
              </Grid>
              <Grid item xs={12} md={1}></Grid>
              <Grid item xs={11} md={5}>
                <img src={TrackingExample} alt="Tracking Example" width="100%" className={classes.example}/>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>


      <Box className={classes.features}>
        <Container maxWidth="lg">
          <Box py={8}>
            <Grid container justify="center" spacing={3}>
              <Hidden smDown>
                <Grid item xs={12} md={5}>
                  <img src={NavigationExample} alt="Navigation Example" width="100%" className={classes.example}/>
                </Grid>
              </Hidden>
              <Grid item xs={12} md={1}></Grid>
              <Grid item xs={11} md={5}>
                <Typography variant="h4" component="h3" gutterBottom>
                  <Box display="flex" alignItems="center" flexWrap="wrap" wordSpacing={2} mt={{xs: 0, md: 6}}>
                    <Explore className={classes.cardIcon} /> Other Features
                  </Box> 
                </Typography>
                <Typography variant="h6" color="textSecondary" component="p" gutterBottom>
                  Budgeting with a Twist™ 
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p" gutterBottom>
                  You're not constrained to the typical "monthly buget".  You can start your budget whenever you want so you always know how much money you'll have to cover your bills!
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p" gutterBottom>
                  In addition to this unique feature, premium users can...
                  <ul>
                    <li>Customize your categories to fit your lifestyle</li>
                    <li>Set savings goals, with automatic budget recommendations</li>
                    <li>Tag transactions to quickly find related events</li>
                    <li>Search and filter to find transactions quickly</li>
                  </ul>
                </Typography>
                {/*<Button size="small">Learn More</Button>*/}
              </Grid>
              <Hidden mdUp>
                <Grid item xs={11} md={7}>
                  <img src={NavigationExample} alt="Navigation Example" width="100%" className={classes.example}/>
                </Grid>
              </Hidden>
            </Grid>
          </Box>
        </Container>
      </Box>





      <Footer/>


    </BaseLayout>
  )
}